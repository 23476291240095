(function () {
  //최근검색어
  var recentItemTemplate =
    '<li><span class="box"><a href="/search/result?search={{text}}&force=false" data-value="{{text}}" data-contents="최근 검색어">{{disp_text}}</a><button type="button" class="btn-delete" title="검색어 삭제"><span class="blind">삭제</span></button></span></li>';
  //추천검색어
  var popularItemTemplate =
    '<li><a href="/search/result?search={{text}}&force=false" data-value="{{text}}">{{#if type=="Y"}}<span class="flag">HIT</span>{{/if}}{{text}}</a></li>';
  //LGE PICK
  var pickItemTemplate =
    '<li><a href="{{_linkUrl}}">{{#if _contents!=""}}<span class="flag exhibition">{{_contents}}</span>{{/if}}{{text}}</a></li>';
  //이런 제품을 찾고 계신가요?/오늘의 랭킹 제품
  var prductListTemplate =
	'<li>' +
	'  <a href="{{_modelUrlPath}}" class="item__link">' +
	'    <div class="item__image">' +
	'      <img src="{{smallImageAddr}}" alt="">' +
	'    </div>' +
	'    <div class="item__info">' +
	'      <div class="item__name">' +
	'        <span class="main-title">' +
	'          <span class="main">{{#raw modelDisplayName}}{{#if _keywdNmText!=""}}<em class="sub">{{_keywdNmText}}</em>{{/if}}{{#if _keywdNmText1!=""}}<em class="sub">{{_keywdNmText1}}</em>{{/if}}</span>' +
	'        </span>' +
	'      </div>' +
	'      <div class="item__model-wrap">' +
	'        <span class="item__sku">{{#raw modelName}}</span>' +
	'      </div>' +
	'    </div>' +
	'  </a>' +
	'</li>';
  //자동완성
  var autoCompleteItemTemplate =
    '<li{{#if type!=""}} class="line-area-wrap"{{/if}}><a href="#{{input}}" data-type="{{type}}">{{#if type!=""}}<span class="line-area01">{{/if}}{{#raw text}}{{#if type!=""}}</span><span class="type">{{typeNm}}</span>{{/if}}</a></li>';
  //자동완성 상품리스트
  var autoCompletePrductListTemplate = 
	'<li>' + 
	'  <a href="{{_modelUrlPath}}" >' + 
	'    <span class="prod-img"><img src="{{_imageUrl}}" onError="lgkorUI.addImgCateErrorEvent(this, \'{{noImageUrl}}\' );" alt="{{modelName}}"></span>' + 
	'    <div class="model-info">' + 
	'        <p class="model-name">{{#raw _modelName}}</p>' + 
	'        <span class="main-title">' +
	'            <span class="main">{{#raw modelDisplayName}}{{#if _keywdNmText!=""}}<em class="sub">{{_keywdNmText}}</em>{{/if}}{{#if _keywdNmText1!=""}}<em class="sub">{{_keywdNmText1}}</em>{{/if}}</span>' + 
	'        </span>' + 
	'    </div>' + 
	'    <span class="txt-direct">제품 바로가기</span>' + 
	'  </a>' + 
	'</li>';
  //자동완성 브랜드리스트
  var autoCompleteBrandListTemplate =
    '<a href="{{_brandUrlPath}}">' +
    '  <span class="brand-img {{brandCss}}"><span class="blind">{{brandName}}</span></span>' +
    '  <span class="txt-direct"><span class="brand-name">{{brandName}}</span> 브랜드관 바로가기</span>' +
    '</a>';
  //자동완성 대체키워드
  var similarTextTemplate =
    '‘<span class="srch-word">{{text}}</span>’(으)로 검색어를 수정합니다.';
  //최근검색어 저장 공유 도메인 e.g. ".lge.co.kr"
  var lgeHost = location.hostname.replace(/.+(?=lge\.)/i, ".");

  var linkHost =
    window.LGEAPPHostName === "localhost" ? "https://www.lge.co.kr" : "";

  var intergratedSearch = {
    init: function () {
      var self = this;
      self.setting();
      self.bindEvents();
    },

    setting: function () {
      var self = this;

      //타이머
      self.searchTimer = null;
      //통합검색 레이어
      self.$searchLayer = $("#layerSearch");
      //검색어 입력input
      self.$inputSearch = self.$searchLayer.find("div.input-sch input.txt");
      self.$inputSearch.attr("data-autofocus", true);
      //검색버튼
      self.$buttonSearch = self.$searchLayer.find("div.input-sch button.btn-search");
      self.$cameraSearch = self.$searchLayer.find("div.input-sch button.btn-camera");
      //검색 전 리스트 공간
      self.$searchKeywordArea = self.$searchLayer.find("div.search-keyword-area");
      //검색 전 제품 공간
      self.$searchProductArea = self.$searchLayer.find("div.search-right-area");
      //최근검색어 리스트 전체삭제
      self.$recentKeywordDelete = self.$searchKeywordArea.find("div.all-delete");
      //최근검색어 리스트
      self.$recentKeywordList = self.$searchKeywordArea.find("div.recent-keyword");
      //추천검색어 리스트
      self.$recommendKeywordList = self.$searchKeywordArea.find("div.popular-keyword");
      //추천검색어 새로보기
      self.$recommendRefresh = self.$searchLayer.find("button.btn-refresh");
      //LGE PICK
      self.$pickKeywordList = self.$searchKeywordArea.find("div.symptom-keyword");
      //오늘의 랭킹 제품
      self.$rankProductList = self.$searchProductArea.find("div.today-ranking");
      //이런 제품을 찾고 계신가요? 제품
      self.$recommendProductList = self.$searchProductArea.find("div.search-recommend");
      //자동 완성 & 검색 결과 공간
      self.$searchResultArea = self.$searchLayer.find("div.search-result-area");
      //자동 완성 리스트
      self.$autoComplete = self.$searchResultArea.find("div.auto-complete");
      //대체키워드
      self.$searchSimilar = self.$searchLayer.find("div.keyword-modify-wrap");
      //돋보기 숨김
      $('li.search>a[href="#layerSearch"]').removeAttr("data-control");
      self.$searchLayer.attr("aria-hidden", true).show();
      // 레이아웃 초기화
      self.hideSearchResultArea();
      self.$searchSimilar.hide();
      self.toggleCamera(true);
    },

    sendSearchPage: function (searchUrl, search, unit, force) {
      if (searchUrl) {
        var fi = searchUrl.indexOf("?");
        var url =
          searchUrl +
          (fi < 0 ? "?" : "&") +
          "search=" +
          encodeURIComponent(search) +
          (unit != null && unit != "" ? "&unit=" + unit : "") +
          "&force=" +
          force;
        location.href = url;
      }
    },

    bindEvents: function () {
      var self = this;

      $('li.search>a[href="#layerSearch"]')
        .off(".intergrated")
        .on("click.intergrated", function (e) {
          self.preScrollTop = $(window).scrollTop();
          $(window).scrollTop(0);
          e.preventDefault();
          self.getRecentlyViewedCategoryId();
          self.updateRecommendData();
          self.updateRecentSearchList();

          self.$searchLayer.attr("aria-hidden", false).addClass("open");
          self.$inputSearch.val("");
          self.$inputSearch.focus();
          var ignoreOverflow = $("body").hasClass("ignore-overflow-hidden");
          if (!ignoreOverflow) {
            $("body").addClass("scroll-lock");
          }
          $('html').css('overflow-y', 'auto');
          
            // 모바일웹,앱 하단메뉴 노출 여부 설정
            lgkorUI.showBottomMeun(false)
        });

      //통합검색 닫음
      self.$searchLayer
        .find("button.btn-close")
        .off(".intergrated")
        .on("click.intergrated", function (e) {
          e.preventDefault();

          self.closeSearchPopup();
          if(self.preScrollTop){
            $(window).scrollTop(self.preScrollTop);
          }

            // 모바일웹,앱 하단메뉴 노출 여부 설정
            lgkorUI.showBottomMeun(true)
        });

      //검색버튼
      self.$buttonSearch
        .off(".intergrated")
        .on("click.intergrated", function (e) {
          e.preventDefault();
          clearTimeout(self.searchTimer);

          var searchVal = self.$inputSearch.val();
          self.sendKeyword(searchVal, "1", "1"); //[BTOCSITE-59848] 검색어 집계 세분화
          self.requestSearchInput(searchVal);
        });

      self.$inputSearch.keydown(function (key) {
        if (key.keyCode == 13) {
          key.preventDefault();
          self.$buttonSearch.trigger("click");
        }
        if (key.keyCode == 38 || key.keyCode == 40) { 
          var $links = self.$autoComplete.find('div.keyword-list ul li'),
          linkIndex =   0
          count = $links.length;
          if(count > 0 ){ 
            $links.each(function( index, element ) {
              if ($(element).hasClass("on") ) linkIndex = index+1;
              $(element).removeClass("on")
            });
            switch (key.keyCode) {
                case 38:
                    // up
                    linkIndex = Math.max(0, linkIndex - 1) ; 
                    break;
                case 40:
                    // down
                    linkIndex = Math.min(count, linkIndex + 1) ; 
                    break;
            }
            if(linkIndex > 0 ) linkIndex = linkIndex -1;  
            $links.eq(linkIndex).addClass('on'); 
            self.$inputSearch.val($links.eq(linkIndex).find('a').attr("href").replace("#", ""));
          }
        }
      });

      // 카메라버튼
      self.$cameraSearch.on("click", function (e) {
        var target = $(e.currentTarget);

        self.callCheckLogin(function (result) {
          var uri = "/my-page/manage-products";

          if (!result.data.isLogin) {
            lgkorUI.alert(
              "<h6>보유제품을 등록하시려면<br />로그인이 필요합니다.</h6>",
              {
                ok: function () {
                  // 보유제품 페이지로 던지면 미로그인시 로그인 페이지로 가기에
                  if (uri) lgkorUI.goUrl({ href: uri + "?searchQr=qr" });
                },
              },
              target
            );
          } else {
            if (uri) lgkorUI.goUrl({ href: uri + "?searchQr=qr" });
          }
        });
      });

      //검색 타이머
      self.$inputSearch
        .off(".intergrated")
        .on("input.intergrated", function (e) {
          clearTimeout(self.searchTimer);

          var reg = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim;
          var searchVal = this.value.trim();
          var searchTestVal = searchVal.replace(reg, "");
          if (searchVal.length < lgkorUI.SEARCH_AUTOCOMPLETE_MIN_LENGTH || searchTestVal.length < lgkorUI.SEARCH_AUTOCOMPLETE_MIN_LENGTH) {
            self.openSearchInputLayer(true);
            self.hideSearchResultArea();
            self.$searchSimilar.hide();
            self.toggleCamera(true);
            return;
          } else {
            self.searchTimer = setTimeout(function () {
              self.requestSearchAutoComplete(searchVal);
            }, lgkorUI.SEARCH_AUTOCOMPLETE_TIMER);
            self.toggleCamera(false);
          }
        });

      //자동완성 리스트 클릭
      self.$autoComplete
        .off(".intergrated")
        .on("click.intergrated", "div.keyword-list ul li a", function (e) {
          e.preventDefault();
          self.searchItem($(this));
          
          //[BTOCSITE-59848] 검색어 집계 세분화
          var searchVal = $(this).attr("href").replace("#", "");
          self.sendKeyword(searchVal, "3", "4");
        });

      //자동완성 화살표 클릭
      self.$autoComplete.on(
        "click.intergrated",
        "div.keyword-list ul li button",
        function (e) {
          e.preventDefault();
          self.searchItem($(this).prev("a"), false);

          self.$inputSearch.focus();
        }
      );
      
      //추천검색어 클릭
      self.$recommendKeywordList
        .off(".intergrated")
        .on("click.intergrated", "div.keyword-list ul li a", function (e) {
          e.preventDefault();
          self.searchItem($(this));
          
          //[BTOCSITE-59848] 검색어 집계 세분화
          /*var searchVal = $(this).attr("href").replace("#", "");*/
          var searchVal = $(this).attr("data-value");
          self.sendKeyword(searchVal, "3", "2");
        });

      //최근검색어 클릭
      self.$recentKeywordList
        .off(".intergrated")
        .on("click.intergrated", "div.keyword-list ul li span a", function (e) {
          e.preventDefault();
          self.searchItem($(this));
          
          //[BTOCSITE-59848] 검색어 집계 세분화
          var searchVal = $(this).attr("data-value");
          self.sendKeyword(searchVal, "3", "1");
        })
        .on(
          "click.intergrated",
          "div.keyword-list ul li span button",
          function (e) {
            //최근검색어 삭제 클릭
            var text = $(this)
              .siblings("a")
              .first()
              .attr("data-value");
            self.removeRecentSearcheText(text);
          }
        )
        .on("click.intergrated", ".all-delete > a", function (e) {
          e.preventDefault();
          self.removeRecentSearcheText();
        });
      
      //추천검색어 새로보기
      self.$recommendRefresh
      .off(".intergrated")
      .on("click.intergrated", function (e) {
        e.preventDefault();
        self.updateRecommendData();
      });
      
      //검색 레이어 바깥영역 클릭시 닫기
      $(document).mouseup(function(e) {
    	  if(self.$searchLayer.hasClass("open")){
    		  if($(e.target).closest(self.$searchLayer).length === 0) {
    			  self.closeSearchPopup();
    		  }
    	  }
      });      

      //자동완성 링크로 이탈 후 검색모달 닫기
      $(window).on('pageshow',function(){
        self.$searchKeywordArea.show();
        self.$autoComplete.hide();
      });
      $(window).on('pagehide',function(){
        self.closeSearchPopup();
      });
    },

    /**
     * 로그인 체크
     */
    callCheckLogin: function (callback) {
      var ajaxUrl = $("header").data("login-info");

      if (!ajaxUrl) {
        return;
      }

      lgkorUI.requestAjaxDataPost(
        ajaxUrl,
        {},
        function (result) {
          if (callback) callback(result);
        }.bind(this)
      );
    },

    openSearchPopup: function () {
      var self = this;
      self.$searchLayer.attr("aria-hidden", false).addClass("open");

      self.$inputSearch.focus();

      //
      var ignoreOverflow = $("body").hasClass("ignore-overflow-hidden");
      if (!ignoreOverflow) {
        $("html, body").css({
          overflow: "hidden",
        });
      }
    },

    closeSearchPopup: function () {
      var self = this;
      clearTimeout(self.searchTimer);
      self.$searchLayer.attr("aria-hidden", true).removeClass("open");

      var ignoreOverflow = $("body").hasClass("ignore-overflow-hidden");
      if (!ignoreOverflow) {
        $("body").removeClass("scroll-lock");
      }
      
      //자동완성
      self.$autoComplete.hide();
      self.openSearchInputLayer(true);
    },

    //검색어창에 입력후 검색
    searchItem: function ($item, isTrigger) {
      var self = this;
      var trigger = isTrigger === undefined ? true : isTrigger;

      var searchVal = $item.attr("data-value") || $item.attr("href").replace("#", "");
      self.$inputSearch.val(searchVal);
      
      var unit = $item.data("type");
      
      if (trigger) {
    	//[BTOCSITE-59848] 검색어 집계 세분화
    	clearTimeout(self.searchTimer);
        self.requestSearchInput(searchVal, unit);
        //self.closeSearchPopup();
      }
    },

    /**
     * 카메라 버튼 토글
     * @param {boolean} show
     */
    toggleCamera: function (show) {
      var self = this;

      if (isApp() && show) {
        self.$buttonSearch.hide();
        self.$cameraSearch.show();
      } else {
        self.$buttonSearch.show();
        self.$cameraSearch.hide();
      }
    },

    openSearchInputLayer: function (open) {
      var self = this;
      var breakpoint = window.breakpoint;
      var device = breakpoint.name;
      
      if (open) {
        self.updateRecentSearchList();
        self.$searchKeywordArea.show();
        self.$searchProductArea.show();
      } else {
        self.$searchKeywordArea.hide();
        if (device == "mobile") {
        	self.$searchProductArea.hide();
        }else {
        	self.$searchProductArea.show();
        }
      }
    },

    hideSearchResultArea: function () {
      var self = this;
      //자동완성
      self.$autoComplete.hide();
    },

    //검색어 입력중 검색
    requestSearchAutoComplete: function (value) {
      var self = this;
      var ajaxUrl = self.$searchLayer.data("autocompleteUrl");
      lgkorUI.requestAjaxData(ajaxUrl, { search: value }, function (result) {
        var param = result.param;
        var data = result.data;

        var searchedValue = param.search;
        var replaceText =
          '<span class="search-word">' + searchedValue + "</span>";
        var showAutoComplete = false;

        //자동완성 매핑 상품 리스트
        var arrPrductList = data && data.prductList instanceof Array ? data.prductList : [];
        var $prductList_div = self.$autoComplete.find("div.prod-direct-list");
        var $prductList_ul = self.$autoComplete.find("div.prod-direct-list ul");
        $prductList_ul.empty(); 
        $prductList_div.hide();
        if (arrPrductList.length > 0) {
      	  $prductList_div.show();
	      arrPrductList.forEach(function (item, index) {
	    	if (index == 0) {
	    		item._imageUrl = linkHost + item.smallImageAddr; 
		      	item._modelName = vcui.string.replaceAll(item.modelName, searchedValue, replaceText);
        		item._keywdNmText = "";
            	item._keywdNmText1 = "";
            	var keywdNm = item.keywdNm || "";
    			if(keywdNm != "") {
    				if (keywdNm.split("^").length > 1) {
    					item._keywdNms = keywdNm.split("^").map(function (elm, idx) {
    						if (idx === 0) item._keywdNmText = elm.trim();
    						if (idx === 1) item._keywdNmText1 = elm.trim();
    					});
    				} else {
    					item._keywdNmText = keywdNm;
    				}
    			}
    			// URL 링크 > 집계를 위한 URL 추가
    			item._modelUrlPath = self.getUrlPath(item.modelUrlPath, 'com_input_product', (index+1));
		      	$prductList_ul.append(vcui.template(autoCompletePrductListTemplate, item));
	    	}
	      });
	      showAutoComplete = true;
        }          
        //자동완성 매핑 브랜드 리스트
        var arrBrandList = data && data.brandList instanceof Array ? data.brandList : [];
        var $brandList_ul = self.$autoComplete.find("div.brandhall");
        $brandList_ul.empty();
        $brandList_ul.hide();
        if (arrBrandList.length > 0) {
        	$brandList_ul.show();
        	arrBrandList.forEach(function (item, index) {
        		// URL 링크 > 집계를 위한 URL 추가
    			item._brandUrlPath = self.getUrlPath(item.brandUrlPath, 'com_input_brand', (index+1));
        		$brandList_ul.append(vcui.template(autoCompleteBrandListTemplate, item));
            });
            showAutoComplete = true;
        }
        //자동완성 매핑 키워드 리스트
        var arr = data && data.listData instanceof Array ? data.listData : [];
        var $list_ul = self.$autoComplete.find("div.keyword-list ul");
        $list_ul.empty();
        if (arr.length > 0) {
			arr.forEach(function (item, index) {
				$list_ul.append(
					vcui.template(autoCompleteItemTemplate, {
						input: item.text,
						type: item.type,
						typeNm: item.typeNm,
						text: vcui.string.replaceAll(item.text, searchedValue, replaceText)
					})
				);
			});
			showAutoComplete = true;
        }
        
        //대체키워드가 있으면 대체키워드를 표시하고 아니면 숨기기
        if (data.similarText) {
            self.$searchSimilar.html(
              vcui.template(similarTextTemplate, { text: data.similarText })
            );
            self.$searchSimilar.addClass("on");
            self.$searchSimilar.show();
        } else {
        	self.$searchSimilar.removeClass("on");
            self.$searchSimilar.hide();
        }
        
        //자동완성 갱신
        if (showAutoComplete) {
          self.$autoComplete.show();
          self.openSearchInputLayer(false);
        } else {
          self.hideSearchResultArea();
        }
      });
    },

    //최근 검색어 삭제
    removeRecentSearcheText: function (text) {
      var self = this;

      // 쿠키 *.lge.co.kr 에 공유하기 위함
      lgkorUI.cookieDomain = lgeHost;

      if (text) {
        lgkorUI.removeCookieArrayValue(lgkorUI.INTERGRATED_SEARCH_VALUE, text);
      } else {
        lgkorUI.deleteCookie(lgkorUI.INTERGRATED_SEARCH_VALUE);
      }

      self.updateRecentSearchList();

      lgkorUI.cookieDomain = null;
    },

	//최근 검색어 추가
	addRecentSearcheText: function (text) {
		// 스페이스바(공백제거) & 특수문자 치환(¡¿¡)
		var _text = text.trim().replaceAll('|','').replaceAll('&','¡¿¡');

		if (!_text || _text.length < 1) return;
		var self = this;

		// 쿠키 *.lge.co.kr 에 공유하기 위함
		lgkorUI.cookieDomain = lgeHost;
		lgkorUI.addCookieArrayValue(lgkorUI.INTERGRATED_SEARCH_VALUE, _text, lgkorUI.MAX_SAVE_RECENT_KEYWORD); 
		self.updateRecentSearchList();
		lgkorUI.cookieDomain = null;
	},

    //최근 검색어 리스트 갱신
    updateRecentSearchList: function () {
      var self = this;

      var cookieValue = lgkorUI.getCookie(lgkorUI.INTERGRATED_SEARCH_VALUE);
      var searchedList = cookieValue ? cookieValue.split("|") : [];

      var arr = searchedList instanceof Array ? searchedList : [];
      var $list_ul = self.$recentKeywordList.find("div.keyword-list ul");
      $list_ul.empty();
      if (arr.length > 0) {
        arr.forEach(function (item, index) {
			var _text = item.trim().replaceAll('¡¿¡','&');
            if(_text) {
                var disp_text = _text;
                if (disp_text.length > 15) disp_text = disp_text.substring(0, 15) + "...";
                $list_ul.append(vcui.template(recentItemTemplate, { text: _text, disp_text: disp_text }));
            }
        });
        self.$recentKeywordDelete.show();
        self.$recentKeywordList.find("div.no-data").hide();
      } else {
    	self.$recentKeywordDelete.hide();
        self.$recentKeywordList.find("div.no-data").show();
      }
    },
    
    //최근본제품 categoryId
    getRecentlyViewedCategoryId: function () {
        var self = this;
        var ajaxUrl = self.$searchLayer.data("recentlyViewedUrl");
        //최근본제품 categoryId
        lgkorUI.requestAjaxDataPost(ajaxUrl, null, function (result) {
        	var categoryId = "";
        	var arr = result.data.listData && result.data.listData instanceof Array ? result.data.listData : [];
        	if (arr.length > 0) {
        		categoryId = arr[0].categoryId;
        	}
        	self.updateBasicData(categoryId);
        }, null, null, function () {
        	self.updateBasicData("");
        });
    },

    //기초 데이타 갱신
    updateBasicData: function (categoryId) {
      var self = this;
      var ajaxUrl = self.$searchLayer.data("basicUrl");
      lgkorUI.requestAjaxDataPost(ajaxUrl, {categoryId: categoryId}, function (result) {
        var data = result.data;

        //LGE PICK
        var arr = data.listData && data.listData instanceof Array ? data.listData : [];
        if (arr.length > 0) {
          var $list_ul = self.$pickKeywordList.find("ul");
          $list_ul.empty();
          arr.forEach(function (item, index) {
        	//exhibition(기획전),event(스스로해결)
        	item._contents = item.contents || "";
        	// URL 링크 > 집계를 위한 URL 추가
    		item._linkUrl = self.getUrlPath(item.linkUrl, 'com_input_tag', (index+1));
            $list_ul.append(vcui.template(pickItemTemplate, item));
          });
          self.$pickKeywordList.show();
        } else {
          self.$pickKeywordList.hide();
        }
        
        //이런 제품을 찾고 계신가요?
        arr = data.productList && data.productList instanceof Array ? data.productList : [];
        if (arr.length > 0) {
        	var $recommend_ul = self.$recommendProductList.find("ul");
        	$recommend_ul.empty();
        	arr.forEach(function (item, index) {
        		item._keywdNmText = "";
            	item._keywdNmText1 = "";
            	var keywdNm = item.keywdNm || "";
    			if(keywdNm != "") {
    				if (keywdNm.split("^").length > 1) {
    					item._keywdNms = keywdNm.split("^").map(function (elm, idx) {
    						if (idx === 0) item._keywdNmText = elm.trim();
    						if (idx === 1) item._keywdNmText1 = elm.trim();
    					});
    				} else {
    					item._keywdNmText = keywdNm;
    				}
    			}
    			// URL 링크 > 집계를 위한 URL 추가
        		item._modelUrlPath = self.getUrlPath(item.modelUrlPath, 'com_input_similar', (index+1));
        		$recommend_ul.append(vcui.template(prductListTemplate, item));
            });
        	self.$recommendProductList.show();
        	self.$rankProductList.hide();
        }else {
        	//오늘의 랭킹
        	ajaxUrl = self.$searchLayer.data("bestRankingUrl");
        	var param = {
      	      rankingType: "todays",
      	      categoryId: "",
      	      filterId:"",
      	      keyword:"",
      	      categoryIds: "CT50000025,CT50000143,CT50000131,CT50000046,CT50000065,CT50000110,CT50000107,CT50000106,CT50000101,CT50000086,CT50000111,CT50019018,CT50000076,CT50000055"
	        };
        	
        	lgkorUI.requestAjaxDataPost(ajaxUrl, param, function (result) {
        		if(result.data[0].bestRankingProductList != null){
        			var $rank_ul = self.$rankProductList.find("ul");
        			$rank_ul.empty();
                	
                    var bestRankingList = result.data[0].bestRankingProductList;
                    if(bestRankingList.length >= 6){
                        bestRankingList = bestRankingList.slice(0,6);
                    }
                    
                    bestRankingList.forEach(function (item, index) {
                    	item._keywdNmText = item.keywdNmA || "";
                    	item._keywdNmText1 = item.keywdNmB || "";
                    	// URL 링크 > 집계를 위한 URL 추가
                		item._modelUrlPath = self.getUrlPath(item.modelUrlPath, 'com_input_best', (index+1));
                        $rank_ul.append(vcui.template(prductListTemplate, item));
                    });
                    self.$recommendProductList.hide();
                	self.$rankProductList.show();
                }else{
                	self.$recommendProductList.hide();
                 	self.$rankProductList.hide();
                }
        	});
        }
      });
    },
    
    //추천검색어
    updateRecommendData: function () {
        var self = this;
        var ajaxUrl = self.$searchLayer.data("recommendUrl");
        lgkorUI.requestAjaxDataPost(ajaxUrl, null, function (result) {
          var data = result.data;

          //추천검색어
          var arr = data.listData && data.listData instanceof Array ? data.listData : [];

          if (arr.length > 0) {
            var $list_ul = self.$recommendKeywordList.find("div.keyword-list ul");
            $list_ul.empty()
            arr.forEach(function (item, index) {
              $list_ul.append(vcui.template(popularItemTemplate, item));
            });
            self.$recommendKeywordList.show();
          } else {
            self.$recommendKeywordList.hide();
          }
        });
    },

    //검색버튼 검색
    requestSearchInput: function (value, unit) {
      //BTOCSITE-91 검색 바로가기 개발요청
      var self = this;
      var ajaxUrl = self.$searchLayer.data("allUrl");
      
      if (unit != null && unit != "") {
    	  ajaxUrl = self.$searchLayer.data("unitUrl");
      }

      lgkorUI.requestAjaxData(
        linkHost + "/search/searchKeyword.lgajax",
        { keyword: value },
        function (result) {
          if (result.data && result.data.success == "Y" && result.data.url) {
            if (result.data.linkTarget == "self") {
              location.href = result.data.url;
            } else {
              if (isApp()) {
                if (vcui.detect.isIOS) {
                  var jsonString = JSON.stringify({
                    command: "sendOutLink",
                    url: result.data.url,
                  });
                  webkit.messageHandlers.callbackHandler.postMessage(
                    jsonString
                  );
                } else {
                  void android.openLinkOut(result.data.url);
                }
              } else {
                window.open(result.data.url, "_blank");
              }
            }
          } else {
            self.sendSearchPage(ajaxUrl, value, unit, false);
          }
        }
      );
    },
    
    //[BTOCSITE-59848] 검색어 집계 세분화
    //type:1.입력쿼리 > typeDetail:1.검색어입력창
    //type:2.전환쿼리 > typeDetail:1.필터,2.정렬,3.전체보기
    //type:3.클릭쿼리 > typeDetail:1.최근검색어,2.인기검색어,3.연관검색어,4.자동완성,5.대체키워드
    sendKeyword: function (query, type, typeDetail, unit) {
        var site = "web";
        if (isApp()) {
      	  site = "app";
        }else if (vcui.detect.isMobile) {
      	  site = "mobile";
        }
        var param = {
      	query: query,
      	site: site,
      	unit: unit || "all",
      	type: type,
      	typeDetail: typeDetail
        };
        var ajaxUrl = "/search/keywordrecord.lgajax";
        if (ajaxUrl) {
          lgkorUI.requestAjaxDataPost(ajaxUrl, param, null, true);
        }
    },    
    
    // URL 링크 > 집계를 위한 URL 추가
	// 예시) "sKwd=검색어&sTab=unit_product_list&sRank=1-1"
	getUrlPath: function (urlPath, tabType, idx) {
		var _modelUrlPath = urlPath || "";
		var _schVal = this.$inputSearch.val() || "";
		
		if(_modelUrlPath) {
			_modelUrlPath = _modelUrlPath + (_modelUrlPath.indexOf('?') > -1 ? '&' : '?') + (_schVal !='' ? 'sKwd=' + _schVal + '&' : '') + ('sTab='+tabType+'&sRank='+(idx));
		}
		return _modelUrlPath;
	},
  };

  $(document).ready(function () {
    intergratedSearch.init();
  });
})();

